import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DiwaliData23 from '../../../../../../Data/Events/2023/DiwaliData23';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Diwali2024 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    DiwaliData23.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Celebrating Diwali: A Festival of Light and Unity at Holistic Learning</h1>
                            <p>At Holistic Learning, we believe in celebrating not just milestones but the diverse range of cultures that shape our team, our community, and our world. As Diwali approaches, We consider the more profound significance of this lovely celebration of lights, which has a particular place in many people's hearts. Diwali is more than just a celebration; it's an occasion to unite, spread happiness, and reinforce the principles of harmony, prosperity, and kindness.</p>
                            <h2>What Diwali Represents</h2>
                            <p>The victory of light over darkness, good over evil, and wisdom over ignorance is symbolized by Diwali, also called the Festival of Lights. Millions of people worldwide view Diwali as a time of renewal when friends and family get together to rejoice, light up their houses, and trade presents. During this period, the mind, body, and soul are cleansed, and the value of optimism and hope in conquering obstacles is emphasized.</p>
                            <p>Diwali speaks to the core of what our organization aspires to represent. It shows how dedicated we are to creating a culture of collaboration, respect and understanding. In our opinion, creating a welcoming and encouraging work environment makes us all shine brighter together, just like Diwali lights up homes and hearts.</p>
                            <h2>The Spirit of Diwali at Holistic Learning</h2>
                            <p>As a company, we encourage the celebration of Diwali in all its forms, whether through festive decorations in the office, Diwali-themed activities, or just taking a moment to reflect on the year gone by. A beautiful rangoli is a must-have for Diwali, and this year, we worked really hard to create one with vibrant color. As we excitedly decorated it and added our own unique touch to the event, the air was filled with a joyful vibe.</p>
                            <p>Our Diwali 2024 festivities began with a traditional Puja, in which we prayed to Goddess Lakshmi,  the goddess of prosperity and wealth. We had a delicious lunch after praying, which gave us energy for the remainder of the day. A number of imaginative games, team challenges, and activities that united everyone were the event's high point. We may interact and gain knowledge from one another's experiences in a variety of ways, from exchanging customary treats to organizing a few fun activities. These times of mutual happiness enable us to celebrate and deepen our team engagement. It was a chance for everyone to feel included, valued, and appreciated for their uniqueness. This year, we planned a series of events to bring everyone together, regardless of background, to celebrate this occasion of unity. Our group got together to celebrate a day full of love, laughter, and brightness.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Preeti K</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Diwali2024;